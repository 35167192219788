<template>
  <div>
    <ed-table
    :key="$root.$session.versao"
       strModel="Configuracoes/ContaEmail"
      :headers="arrayHeaders"
      :rows="arrayRows"
      :pagination="objPagination"
      :filter="formFiltros"
      :loading="loading"
      @register="onRegister"
      @edit="onEdit"
      @search="getContaEmail"
    />
  </div>
</template>

<script>
import { EdTable } from "@/components/common/form";

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: { EdTable },
  mounted() {
    this.getContaEmail();
  },
  beforeDestroy() {
    this.arrayHeaders = [];
    this.arrayRows = [];
    this.objPagination = null;
  },
  created() {},
  data() {
    return {
      loading: false,
      arrayHeaders: [
        {
          text: "Nome",
          sortable: true,
          value: "strNome",
        },
        {
          text: "Email",
          sortable: true,
          value: "strEmail",
        },
        {
          text: "Serviços Vinculados",
          sortable: true,
          value: "strServico",
          collapse: 1,
          collapseLabel: "Visualizar",

        },
      ],
      arrayRows: [],
      objPagination: null,
      formFiltros:{}
    };
  },
  methods: {
    onRegister() {
      this.$router.push({ name: "configuracoes.contaEmail.register" });
    },

    onEdit(data) {
      this.$router.push({
        name: "configuracoes.contaEmail.edit",
        params: { intId: data.intId },
      });
    },

    getContaEmail() {
      this.loading = true;
      this.arrayRows = [];

      this.$root.$request
        .get(
          "Configuracoes/ContaEmail",
          this.formFiltros
        )
        .then((objResult) => {
          if (objResult.status == 200) {
            this.objPagination = objResult.result;
            this.loading = false;

            let arrayRows = [];

            objResult.result.data.forEach((objContaEmail) => {

              let arrayServico = []

              objContaEmail.servicos.forEach(element => {
                arrayServico.push(element.tipo_servico.strNome)
              });
              
              let item = {
                intId: objContaEmail.intId,
                strEmail: objContaEmail.strEmail,
                strNome: objContaEmail.strNome,
                strServico: arrayServico.join('<br>'),
                _item: objContaEmail,
              };

              arrayRows.push(item);
            });

            this.arrayRows = arrayRows;
          }
        });
    },
  },
};
</script>
